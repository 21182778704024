body,html{
    min-width: 1200px;
    height: 100%;
}
#root{
    height: 100%;
}
*{
    box-sizing: border-box;
    padding: 0;
    text-decoration: none;
}
.container{
    position:relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.flex{
    display: flex;
}
.text-center {
    text-align: center;
}
.height_100{
    height: 100%;
}
.work_container{
    flex-grow: 1;
    overflow: auto;
    height: 100%;
    display: flex;
}
hr{
    border: none;
    background-color: #EDEDED;
    height: 1px;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
}
.label {
    text-transform: uppercase;
    font-size: 12px;
    color: #A0A0A0;
    padding-bottom: 10px;
}
button {
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}