@font-face {
  font-family: 'AgoraSansProRegular';
  src: local('AgoraSansProRegular'), url(./fonts/AgoraSansProRegular.ttf) format('truetype');
}
@font-face {
  font-family: 'AgoraSansProBold';
  src: local('AgoraSansProBold'), url(./fonts/AgoraSansProBold.ttf) format('truetype');
}
@font-face {
  font-family: 'AgoraSansProBoldItalic';
  src: local('AgoraSansProBoldItalic'), url(./fonts/AgoraSansProBoldItalic.ttf) format('truetype');
}
@font-face {
  font-family: 'AgoraSansProMedium';
  src: local('AgoraSansProMedium'), url(./fonts/AgoraSansProMedium.ttf) format('truetype');
}
@font-face {
  font-family: 'AgoraSansProMediumItalic';
  src: local('AgoraSansProMediumItalic'), url(./fonts/AgoraSansProMediumItalic.ttf) format('truetype');
}
@font-face {
  font-family: 'AgoraSansProBlack';
  src: local('AgoraSansProBlack'), url(./fonts/AgoraSansProBlack.ttf) format('truetype');
}
@font-face {
  font-family: 'AgoraSansProThin';
  src: local('AgoraSansProThin'), url(./fonts/AgoraSansProThin.ttf) format('truetype');
}
body {
  margin: 0;

  font-family: 'AgoraSansProRegular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.clearboth:after{
  content: '.';
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.flex{
  display: flex;

}
.flex_space_between{
  justify-content: space-between;
}
.flex_space_center{
  justify-content: center;
}
hr{
  width:100%;
  margin: 10px 0;
}
